<template>
    <div class="login-page-wrap">
        <div class="login-background">
            <div class="login-image-background">
                <div class="rezume-logo-wrapper" />
            </div>
        </div>
        <div class="login-section">
            <div class="login-wrap">
                <span>{{ $t('login.title') }}</span>
                <div>
                    <button
                        class="btn login-btn"
                        :class="{
                            'is-failed': error
                        }"
                        @click="googleLogin"
                    >
                        {{ $t('login.google') }}
                        <span class="google-image" />
                    </button>

                    <p v-if="error">
                        {{ $t('login.error') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import config from '@/config';

export default {
    layout: 'blank',

    middleware: ['guest'],

    data() {
        return {
            error: null
        };
    },

    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn'
        })
    },

    async mounted() {
        this.setTimezone();

        if (this.isLoggedIn) {
            this.$router.push('/');

            return;
        }

        const { auth, error } = this.$route.query;

        try {
            if (error) {
                this.error = error;

                return;
            }

            if (auth) {
                await this.login();
            }
        } catch (error) {
            console.error(error);

            this.error = 'Unauthorized';
        }
    },

    methods: {
        ...mapActions({
            login: 'auth/login'
        }),

        googleLogin() {
            window.location.href = `${config.apiBaseUrl}/auth/google`;
        },

        setTimezone() {
            const timezone = this.$dayjs.tz.guess();

            this.$cookies.set('timezone', timezone);
        }
    }
};
</script>

<style>
span.login-error {
    color: red !important;
    font-weight: bold !important;
    font-size: 14px !important;
    font-style: italic;
}
</style>
